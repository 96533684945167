<template>
    <div class="row justify-center" v-if="questionario_caricato">

        <div class="col-12 col-md-10" align="left">

            <legend> <strong>Proponente - Dati professionali</strong> </legend>
            <q-list>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Attività svolta</q-item-label>
                        <q-item-label>{{getRispostaQuestionariFromName("lavoro")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Tipo di inquadramento</q-item-label>
                        <q-item-label>{{getRispostaQuestionariFromName("tipologia_attivita_persona").replaceAll('_',' ')}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Specializzazione</q-item-label>
                        <q-item-label>{{getRispostaQuestionariFromName("specializzazione")}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Attivit&agrave; per Specializzazione</q-item-label>
                        <q-item-label>
                            <div v-for="(attivita,index) in AttivitaPerSpecializzazione" v-bind:key="index">
                                <q-checkbox v-model="checked" :label="attivita.replaceAll('_',' ')" color="teal" disabled />
                            </div>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Sei un professionista iscritto ad un albo e/o abilitato all'esercizio dell'attivita` professionale?</q-item-label>
                        <q-item-label>{{getRispostaQuestionariFromName("iscrizione_albo")}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda("data_inizio_attivita").label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda("data_inizio_attivita").value}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda("data_iscrizione_albo").label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda("data_iscrizione_albo").value}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda("numero_iscrizione_albo").label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda("numero_iscrizione_albo").value}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda("data_ultimo_aggiornamento_prof").label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda("data_ultimo_aggiornamento_prof").value}}</q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>

            <br>
            <legend> <strong>Caratteristiche generali</strong> </legend>
            <q-list separator>
                <q-item>
                    <q-item-section>
                        <q-item-label caption v-show="!isTaylorMade">Compagnia</q-item-label>
                        <q-item-label v-show="!isTaylorMade">
                            {{getCompagniaSelezionata}}
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive" v-bind:key="index">
                    <q-item-section>
                        <q-item-label caption v-show="!isTaylorMade">{{garanzia.label}}</q-item-label>
                        <q-item-label v-show="!isTaylorMade">
                            {{analizzaRisposta(garanzia)}}
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda("chiude_attivita").label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda("chiude_attivita").value}}</q-item-label>
                    </q-item-section>
                    <q-item-section v-if="domandaIsVisible(getObjectDomanda('vuole_postuma'))">
                        <q-item-label caption>{{getObjectDomanda("vuole_postuma").label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda("vuole_postuma").value}}</q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>

            <br>

            <legend> <strong>Informazioni sul fatturato</strong> </legend>
             <q-list>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda("fatturato.fatturato_corrente").label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda("fatturato.fatturato_corrente").value}} Euro</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Fatturato consuntivo {{(new Date().getFullYear() - 1)}}</q-item-label>
                        <q-item-label>{{getRispostaAnalisiRischioFromName('fatturato')}} Euro</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda("fatturato.fatturato_due_anni_fa").label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda("fatturato.fatturato_due_anni_fa").value}} Euro</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda("fatturato.fatturato_tre_anni_fa").label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda("fatturato.fatturato_tre_anni_fa").value}} Euro</q-item-label>
                    </q-item-section>
                </q-item>

            </q-list>

            <br>

            <legend><strong>Precedenti assicurazioni per questo rischio</strong></legend>
             <q-list>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Hai mai stipulato polizze per questo rischio?</q-item-label>
                        <q-item-label>
                            {{havePolizzaStessoRischio}}
                        </q-item-label>
                    </q-item-section>
                </q-item>

                <div v-show="havePolizzaStessoRischio === 'SI' ">
                    <q-item >
                        <q-item-section>
                            <q-item-label caption>Impresa di assicurazione</q-item-label>
                            <q-item-label>{{getRispostaAnalisiRischioFromName('compagnia')}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>Data di scadenza della copertura</q-item-label>
                            <q-item-label>{{getRispostaAnalisiRischioFromName('scadenza_copertura')}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>
                                <q-item-label caption>{{getObjectDomanda('copertura_assicurativa_precedente.nome_assicuratore').label}}</q-item-label>
                                <q-item-label>{{getObjectDomanda('copertura_assicurativa_precedente.nome_assicuratore').value}}&nbsp;</q-item-label>
                            </q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('copertura_assicurativa_precedente.massimale').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('copertura_assicurativa_precedente.massimale').value}} Euro</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('copertura_assicurativa_precedente.franchigie_scoperti').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('copertura_assicurativa_precedente.franchigie_scoperti').value}}&nbsp;</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('copertura_assicurativa_precedente.premio_lordo').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('copertura_assicurativa_precedente.premio_lordo').value}} Euro</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('copertura_assicurativa_precedente.retroattivita').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('copertura_assicurativa_precedente.retroattivita').value}}&nbsp;</q-item-label>
                        </q-item-section>
                    </q-item>
                </div>
                <q-item>
                    <q-item-section >
                        <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                        <q-item-label>{{PolizzaSchifataDaCompagnia}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section v-if="PolizzaSchifataDaCompagnia === 'SI'">
                        <q-item-label caption>{{getObjectDomanda('motivo_rifiuto_compagnia').label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda('motivo_rifiuto_compagnia').value}}</q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>

            <br>
            <legend><strong>Informazioni sui sinistri</strong></legend>
            <q-list>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Hai mai ricevuto richieste di risarcimento inerenti l`attività professionale?</q-item-label>
                        <q-item-label>
                            {{haveRichiesteRisarcimento}}
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <div v-show="haveRichiesteRisarcimento === 'SI'">
                    <q-item>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('sinistri.data_richiesta_risarcimento').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('sinistri.data_richiesta_risarcimento').value}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('sinistri.periodo_contestazione').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('sinistri.periodo_contestazione').value}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('sinistri.valore_richiesta_danni').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('sinistri.valore_richiesta_danni').value}} Euro</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('sinistri.valore_sinistro_pagato').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('sinistri.valore_sinistro_pagato').value}} Euro</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('sinistri.pagamento_con_transazione').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('sinistri.pagamento_con_transazione').value}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('sinistri.pagamento_con_sentenza_civile').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('sinistri.pagamento_con_sentenza_civile').value}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label caption>{{getObjectDomanda('sinistri.descrizione_fatti').label}}</q-item-label>
                            <q-item-label>{{getObjectDomanda('sinistri.descrizione_fatti').value}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </div>

                <q-item>
                    <q-item-section>
                        <q-item-label caption>Sei a conoscenza di circostanze che possono portare ad una richiesta di risarcimento da parte di clienti?</q-item-label>
                        <q-item-label>
                            {{haveConoscenzaPossibiliRichiesteRisarcimento}}
                        </q-item-label>
                    </q-item-section>
                </q-item>

                <q-item v-show="haveConoscenzaPossibiliRichiesteRisarcimento === 'SI'">
                    <q-item-section>
                        <q-item-label caption>{{getObjectDomanda('sinistri.descrizione_possibili_richieste_danni').label}}</q-item-label>
                        <q-item-label>{{getObjectDomanda('sinistri.descrizione_possibili_richieste_danni').value}}</q-item-label>
                    </q-item-section>
                </q-item>

            </q-list>

            <br><br><br><br><br>
        </div>

    </div>
</template>

<script>
    //import QQButton from "@/components/QQButton.vue";
    import { mapState,mapActions } from "vuex";
    import { mapFields} from "vuex-map-fields";
    import commonLib from "../../libs/commonLib";
    //import DomandaQuestionarioAssuntivo from "@/components/QuestionariAssuntivi/DomandaQuestionarioAssuntivo";

    //import { mapGetters } from "vuex";

    export default {
        name: "QQQuestionarioMedicoDaIDPreventivo",
        components: {
            //Domanda: DomandaQuestionarioAssuntivo
        },
        data() {
            return {
                questionario_caricato: false,
                dati_quotazione: {},
                dati_cliente: {},
                checked: true
            }
        },
        props: {
            guid_preventivo: {
                type: String,
                required: true,
                default: ""
            },
            indice_questionario_rischio: {
                type: Number,
                required: true,
                default: 0
            }
        },
        computed: {
            ...mapFields('gestioneQuotazioni', {
                domande: "questionari_assuntivi.rcp_medico.domande"
            }),
            ...mapFields('gestioneQuotazioni', [
                "idquotazione"
            ]),
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPreventivo: state => state.formPreventivo,
                analisi_necessita: state => state.formPreventivo.preventivo.analisi_necessita,
                analisi_rischio: state => state.formPreventivo.preventivo.analisi_rischio,
                is_loading: state => state.formPreventivo.is_loading
            }),
            AttivitaPerSpecializzazione() {
                let attivita = this.analisi_rischio[this.indice_questionario_rischio].domande.find(a => {
                    return a.name === "attivita_per_specializzazione";
                });

                //console.log("attivita:",attivita.value);
                return attivita.value;
            },
            haveRichiesteRisarcimento() {
                if (commonLib.isEmpty(this.analisi_rischio)) {
                    return "NO";
                }
                // return this.analisi_rischio[0].domande[9].value.toUpperCase();
                return this.getRispostaAnalisiRischioFromName('richieste_risarcimento');
            },
            haveConoscenzaPossibiliRichiesteRisarcimento() {
                if (commonLib.isEmpty(this.analisi_rischio)) {
                    return "NO";
                }
                // return this.analisi_rischio[0].domande[11].value.toUpperCase();
                return this.getRispostaAnalisiRischioFromName('rcp_reclami_in_corso');
            },
            PolizzaSchifataDaCompagnia() {
                let value = this.getRispostaAnalisiRischioFromName("polizza_schifata");
                if (value === "") value = "NO";

                return value.toUpperCase();
            },
            havePolizzaStessoRischio() {
                if (commonLib.isEmpty(this.analisi_rischio)) {
                    return "NO";
                }

                //return this.analisi_rischio[0].domande[1].value.toUpperCase();
                return this.getRispostaAnalisiRischioFromName('polizza_in_corso');
            },
            isTaylorMade() {
                //if (commonLib.isEmpty(this.formPreventivo.prodotti)) return false;

                //return this.formPreventivo.preventivo.prodotti[0].is_tailormade;
                return false;
            },
            getCompagniaSelezionata() {
                var result = "";
                if (commonLib.isEmpty(this.formPreventivo)) return result;

                for (var i=0;i< this.formPreventivo.preventivo.prodotti.length;i++) {
                    var prodotto = this.formPreventivo.preventivo.prodotti[i];
                    if ((prodotto.label === "RC Settore Tecnico") &&
                        (prodotto.is_checked)) {
                        var proposte = prodotto.proposte.filter(proposta => {
                            return proposta.is_checked;
                        });

                        if (!commonLib.isEmpty(proposte[0]))
                            result =  proposte[0].compagnia;
                    }
                }

                return result;
            },
            getElencoGaranzieAggiuntive() {
                if (commonLib.isEmpty(this.formPreventivo)) return [];
                var prodotti = this.formPreventivo.preventivo.prodotti;

                if (!Array.isArray(prodotti)) return [];

            //    var prodotto_attivo = prodotti.find(p => {
            //        return (p.label === "RC Settore Medico") && (p.is_checked);
            //    });

                var prodotto_attivo = prodotti.filter(p => {
                    return (p.label === "RC Settore Medico") && (p.is_checked);
                });

                //console.log("prodotto_attivo:",prodotto_attivo);

                var proposte = prodotto_attivo[0].proposte;
                // Cerca quella selezionata
                var proposta = proposte.find(p => {
                    return p.is_checked;
                })

                return proposta.parametri;
            },
        },
        methods: {
            ...mapActions({
                //fetchDatiPreventivo: "formPreventivo/fetchDatiPreventivo",
                fetchDatiQuotazione: "gestioneQuotazioni/fetchDatiQuotazione",
                fetchDatiCliente: "gestioneClienti/fetchDatiCliente",
                loadQuestionariAssuntivi: "gestioneQuotazioni/loadQuestionariAssuntivi"
            }),
            getDatiCliente(index) {
                if (commonLib.isEmpty(this.dati_cliente)) return "";

                return this.dati_cliente[index];
            },
            getObjectDomanda(domanda) {
                //console.log("this.domande:",this.domande);
                if (commonLib.isEmpty(this.domande)) return {};
                var elements = domanda.split(".");
                if (elements.length > 0) {
                    var result = this.domande;
                    elements.forEach(el => {
                        result = result[el];
                    });

                    return result;
                }

                return this.domande[domanda];
            },
            getImportoFatturatoEstero(perc) {
                return parseInt(this.fatturato_totale * perc / 100);
            },
            getDomandaAnalisiNecessitaFromName(name) {
                // non c'è analisi necessita
                if (commonLib.isEmpty(this.analisi_necessita))
                    return null;

                // cerca la domanda
                var domanda = this.analisi_necessita.find(d => {
                    return d.name === name;
                });

                // domanda non trovata
                if(commonLib.isUndefined(domanda)) {
                    return null;
                }

                return domanda;
            },
            getRispostaAnalisiRischioFromName(name) {
                if (commonLib.isEmpty(this.analisi_rischio)) return "";

                // let analisi_rischio_rc = this.analisi_rischio[0];
                let indice_questionario = this.getIndiceQuestionarioRischio;
                let analisi_rischio_rc = this.analisi_rischio[indice_questionario];

                //console.log("analisi rischio completo:",this.analisi_rischio,name);

                if (!commonLib.isEmpty(analisi_rischio_rc)) {
                    var domanda = analisi_rischio_rc.domande.find(d => {
                        return d.name === name;
                    });

                    //console.log("domanda:",domanda);

                    if (commonLib.isEmpty(domanda)) return "";

                    var risposta =  domanda.value;

                    if (!Array.isArray(risposta))
                        return risposta.toUpperCase();

                    return risposta;
                }

                return "";

            },
            getDomandaAnalisiRischioFromName(name) {
                // analisi rischio RC sempre in posizione 0
                // TODO: andare per id

                // non c'è analisi rischio
                if (commonLib.isEmpty(this.analisi_rischio))
                    return null;

                //console.log("getDomandaAnalisiRischioFromName=>indice_prodotto:",this.indice_questionario_rischio);


                // non ci sono domande analisi rischio
                let indice_questionario = this.getIndiceQuestionarioRischio;
                let analisi_rischio_rc = this.analisi_rischio[indice_questionario];

                //console.log("indice questionario:",indice_questionario);
                //console.log("analisi rischio:",analisi_rischio_rc);

                if (commonLib.isEmpty(analisi_rischio_rc)) {
                    return null;
                }

                // cerca la domanda
                var domanda = analisi_rischio_rc.domande.find(d => {
                    return d.name === name;
                });

                // domanda non trovata
                if(commonLib.isUndefined(domanda)) {
                    return null;
                }

                return domanda;
            },
            getRispostaAnalisiNecessitafromName(name) {
                if (!commonLib.isEmpty(this.analisi_necessita)) {
                    var domanda = this.analisi_necessita.find(d => {
                        return d.name === name;
                    });

                    // Se la domanda non e` presente nel questionario allora domanda=undefined
                    if (commonLib.isEmpty(domanda)) return "";

                    return domanda.value.toUpperCase();
                }

                return "";
            },

            getRispostaQuestionariFromName(name) {
                var risposta = this.getRispostaAnalisiRischioFromName(name);
                if (commonLib.isEmpty(risposta)) risposta = this.getRispostaAnalisiNecessitafromName(name);

                return risposta;
            },
            getDomandaQuestionariFromName(name) {
                var domanda = this.getDomandaAnalisiRischioFromName(name);
                if (commonLib.isEmpty(domanda)) domanda = this.getDomandaAnalisiNecessitaFromName(name);

                return domanda;
            },
            getLabelQuestionariFromName(name) {
                var obj = this.getDomandaQuestionariFromName(name);
                if (commonLib.isEmpty(obj)) return "Campo non trovato: "+name;
                return obj.label;
            },

            condizioneIsValidaInDomanda(condizione, domanda)
            {
                // Recupera i nome del campo di riferimento
                // let NomeCampo = condizione.split(":")[0];
                let ValoreCampo = condizione.split(":")[1];

                if (domanda === null) return true;

                // la domanda a cui la condizione fa riferimento non è visibile
                if(!this.domandaIsVisible(domanda)) {
                    //if(condizione == "estensioni_rc_tecnico:AMMINISTRATORE_STABILI") {
                    //    console.log("condizioneIsValida.perc_anno_corrente !domandaIsVisible");
                   // }
                    return false;
                }

                // il value non è valorizzato
                if(commonLib.isNullOrEmpty(domanda.value)) {
                    //if(condizione == "estensioni_rc_tecnico:AMMINISTRATORE_STABILI") {
                    //    console.log("condizioneIsValida.perc_anno_corrente isNullOrEmpty(domanda.value)");
                    //}
                    return false;
                }

                // la risposta è una stringa, ci basta controllare che la
                // condizione contenga la stringa
                // es. risposta "ciccio", condizione "ciccio,pasticcio,mario"
                if(typeof domanda.value === "string") {
                    return ValoreCampo.includes(domanda.value);
                }

                // se la risposta alla domanda è un array, controlliamo che
                // la condizione sia contenuta nell'array di risposta

                // ValoreCampo = val1,val2,val3,val4
                // domanda.value = ["val5", "val1"]
                if(ValoreCampo.indexOf(",") > 1) {
                    var elenco = ValoreCampo.Split(",");
                    var result = true;
                    elenco.forEach(item => {
                        result &= domanda.value.includes(item);
                    });

                    return result;
                }

                return domanda.value.includes(ValoreCampo);
            },
            condizioneIsValida(condizione)
            {
                // Recupera i nome del campo di riferimento
                let NomeCampo = condizione.split(":")[0];
                // let ValoreCampo = condizione.split(":")[1];


                // domanda da controllare è in questionario assuntivo
                if(!commonLib.isEmpty(this.domande[NomeCampo])) {

                //    if(condizione == "estensioni_rc_tecnico:AMMINISTRATORE_STABILI") {
                //        console.log("condizioneIsValida.perc_anno_corrente","questionario assuntivo" );
                //    }

                    let domanda = this.domande[NomeCampo];
                    return this.condizioneIsValidaInDomanda(condizione, domanda);
                }

                // domanda da controllare non è nel questionario assuntivo

                // è in analisi rischio
                var domanda_da_analisi_rischio = this.getDomandaAnalisiRischioFromName(NomeCampo);
                if(domanda_da_analisi_rischio !== null) {
                    return this.condizioneIsValidaInDomanda(condizione, domanda_da_analisi_rischio);
                }

                // è in analisi necessità
                var domanda_da_analisi_necessita = this.getDomandaAnalisiNecessitaFromName(NomeCampo);
                if(domanda_da_analisi_necessita !== null) {
                    return this.condizioneIsValidaInDomanda(condizione, domanda_da_analisi_necessita);
                }

                // la domanda a cui si fa riferimento non c'è da nessuna parte
                return false;
            },
            domandaIsVisible(domanda)
            {
                if (commonLib.isEmpty(domanda)) {
                    return true;
                }

                if (!domanda.is_visible) {
                    return false;
                }

                if (commonLib.isEmpty(domanda.is_visible_if)) {
                    return true;
                }

                // splitta condizioni separate da punto e virgola
                let condizioni = domanda.is_visible_if.split(";");
                let condizioni_valide = true;

                condizioni.forEach(cond => {

                    // se la condizione è valida, prosegui
                    if(this.condizioneIsValida(cond)) {
                        return;
                    }

                    // la condizione non è valida
                    condizioni_valide = false;
                    return false;
                });

                // console.log("domandaIsVisible", domanda.name, condizioni_valide);

                return condizioni_valide;
            },
            getImportoPrecedente(perc) {
                var fatturato = parseInt(this.getRispostaAnalisiRischioFromName("fatturato"));
                var importo = fatturato * parseInt(perc) / 100;

                return parseInt(importo);
            },
            getImportoCorrente(perc) {
                if (commonLib.isEmpty(this.domande)) return 0;

                var fatturato = parseInt(this.domande.fatturato.fatturato_corrente.value);
                var importo = fatturato * parseInt(perc) / 100;

                return parseInt(importo);
            },
            percentuale_fatturato_attivita_ordinaria_anno_precedente() {
                var percentuale = 0;

                //console.log("distribuzione_fatturato:",this.domande.distribuzione_fatturato);

                // Aggiusta la percentuale totale in base alle singole percentuali inserite
                if (!commonLib.isEmpty(this.domande)) {

                    // Itera dentro l'oggetto
                    for (var property in this.domande.distribuzione_fatturato) {
                        var element = this.domande.distribuzione_fatturato[property];

                        percentuale += parseInt(element.perc_anno_precedente.value);
                    }
                }

                return 100 - parseInt(percentuale);
            },
            percentuale_fatturato_attivita_ordinaria_anno_corrente() {
                var percentuale = 0;

                // Itera dentro l'oggetto
                for (var property in this.domande.distribuzione_fatturato) {
                    var element = this.domande.distribuzione_fatturato[property];

                    percentuale += parseInt(element.perc_anno_corrente.value);
                }

                return 100 - parseInt(percentuale);
            },
            analizzaRisposta(garanzia) {
                // type,value,options
                let result = "";
                let value = garanzia.value;
                let options = garanzia.options;
                let type = garanzia.type;


                // Cerca la label tra le options in base alla risposta inserita
                let element = options.filter(elemento => {
                    return elemento.value === value;
                });

                if (element.length === 1) {
                    value = element[0].label;
                }

                if ((value !== null) && (Array.isArray(value))) {
                    value.forEach(valore => {
                        result += '"'+valore+'", ';
                    });
                    result = result.slice(0, -2);
                    return result.replace("_"," ");
                } else {
                    if (value !== null) {
                        if (type === "checkbox") return garanzia.is_checked ? "SI" : "NO";

                        return value.replace("_"," ");
                    } else
                        return "";
                }
            },
        /*    getDomandaAnalisiRischioFromName(name) {
                // analisi rischio RC sempre in posizione 0
                // TODO: andare per id

                // non c'è analisi rischio
                if (commonLib.isEmpty(this.analisi_rischio))
                    return null;

                // non ci sono domande analisi rischio
                let analisi_rischio_rc = this.analisi_rischio[this.indice_questionario_rischio];
                if (commonLib.isEmpty(analisi_rischio_rc)) {
                    return null;
                }

                // cerca la domanda
                var domanda = analisi_rischio_rc.domande.find(d => {
                    return d.name === name;
                });

                // domanda non trovata
                if(commonLib.isUndefined(domanda)) {
                    return null;
                }

                return domanda;
            },
            getRispostaAnalisiRischioFromName(name) {
                // analisi rischio RC sempre in posizione 0
                // TODO: andare per id
                if (commonLib.isEmpty(this.analisi_rischio)) return "";

                let analisi_rischio_rc = this.analisi_rischio[this.indice_questionario_rischio];

                if (!commonLib.isEmpty(analisi_rischio_rc)) {
                    var domanda = analisi_rischio_rc.domande.find(d => {
                        return d.name === name;
                    });

                    if (commonLib.isEmpty(domanda)) return "";

                    var risposta =  domanda.value;

                    if (!Array.isArray(risposta))
                        return risposta.toUpperCase();

                    return risposta;
                }

                return "";

            }, */
        /*    getRispostaAnalisiNecessita(name) {
                if (!commonLib.isEmpty(this.analisi_necessita)) {
                    var domanda = this.analisi_necessita.find(d => {
                        return d.name === name;
                    });

                    // Se la domanda non e` presente nel questionario allora domanda=undefined
                    if (commonLib.isEmpty(domanda)) return "";

                    return domanda.value.toUpperCase();
                }

                return "";
            },*/
            getDatiResidenzaCliente(campo) {
                if(commonLib.isEmpty(this.dati_cliente)) return "";

                let value = this.dati_cliente.altri_dati[0].indirizzi.filter(v => {
                    return v.tipo === "residenza";
                });

                return value[0][campo];
            }
        },
        async mounted() {
            await this.loadQuestionariAssuntivi(this.guid_preventivo);

            this.questionario_caricato = true;
        },
        async activated() {

        }
    }
</script>
