<template>
    <div >
        <br>
        <div align="center">
            <span class="text-h4">Scheda della pratica</span>
        </div>
        <br><br>

        <div class="q-gutter-y-md" style="width: 100%">
            <q-card flat>
                <q-tabs
                    v-model="tab"
                    align="center"
                    narrow-indicator>
                        <q-tab name="generali" label="Informazioni generali" class="text-weight-bolder" />
                        <q-tab name="questionari" label="Analisi necessita / Analisi Rischio" class="text-weight-bolder" />
                        <q-tab name="questionari_assuntivi" label="Questionari assuntivi" class="text-weight-bolder" />
                        <q-tab name="cliente" label="Dati del Cliente" class="text-weight-bolder" />
                        <q-tab name="contratti" label="Contratti" class="text-weight-bolder" />
                        <q-tab name="documenti" label="Documenti" class="text-weight-bolder" />
                </q-tabs>

                <q-tab-panels v-model="tab" animated ref="tabs"
                    transition-prev="jump-down"
                    transition-next="jump-down">

                    <q-tab-panel name="generali">

                        <table class="table">
                            <tbody>
                                <tr>
                                    <td class="domanda">Numero: </td>
                                    <td class="risposta">{{getNumeroPratica}}</td>
                                </tr>
                            <!--
                                    <td class="domanda">Area: </td>
                                    <td class="risposta">{{area}}</td>
                                </tr> //-->
                                <tr>
                                    <td class="domanda">Data di creazione: </td>
                                    <td class="risposta">{{getDataCreazionePratica}}</td>
                                </tr>
                                <tr>
                                    <td class="domanda">Data di decorrenza richiesta: </td>
                                    <td class="risposta">{{getDataDecorrenzaPratica}}</td>
                                </tr>
                                <tr>
                                    <td class="domanda">Identificativo unit&agrave; operativa: </td>
                                    <td class="risposta">{{getIDUnitaOperativa}}</td>
                                </tr>
                                <tr>
                                    <td class="domanda">Stato della pratica: </td>
                                    <td class="risposta">{{getStatoPratica}}</td>
                                </tr>
                                <tr>
                                    <td class="domanda">Note: </td>
                                    <td class="risposta">{{annotazione}}</td>
                                </tr>
                            </tbody>
                        </table>

                    </q-tab-panel>

                    <q-tab-panel name="cliente">

                        <div class="row justify-center">
                            <div class="col-12 col-md-5" style="padding: 8px;">
                                <fieldset>
                                    <legend><strong>Dati anagrafici</strong></legend>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td class="domanda">Nominativo/Ragione Sociale</td>
                                                <td class="risposta">{{getNominativoCliente}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Professione</td>
                                                <td class="risposta">{{getProfessioneCliente}}</td>
                                            </tr>
                                            <tr v-if="isPersonaFisica">
                                                <td class="domanda">Codice Fiscale</td>
                                                <td class="risposta">{{getCodiceFiscaleCliente}}</td>
                                            </tr>
                                            <tr v-if="!isPersonaFisica">
                                                <td class="domanda">Partita IVA</td>
                                                <td class="risposta">{{getPartitaIvaCliente}}</td>
                                            </tr>
                                            <tr v-if="isPersonaFisica">
                                                <td class="domanda">Sesso</td>
                                                <td class="risposta">{{getSessoCliente}}</td>
                                            </tr>
                                            <tr v-if="isPersonaFisica">
                                                <td class="domanda">Data di nascita</td>
                                                <td class="risposta">{{getDataNascitaCliente}}</td>
                                            </tr>
                                            <tr v-if="isPersonaFisica">
                                                <td class="domanda">Comune di nascita</td>
                                                <td class="risposta">{{getComuneNascitaCliente}}</td>
                                            </tr>
                                            <tr v-if="isPersonaFisica">
                                                <td class="domanda">Provincia di nascita</td>
                                                <td class="risposta">{{getProvinciaNascitaCliente}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>

                                <br>
                            </div>
                            <div class="col-12 col-md-5" style="padding: 8px;">
                                <fieldset>
                                    <legend><strong>Residenza / Sede legale</strong></legend>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td class="domanda">Indirizzo</td>
                                                <td class="risposta">{{getIndirizzoResidenza}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Numero civico</td>
                                                <td class="risposta">{{getCivicoResidenza}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Comune</td>
                                                <td class="risposta">{{getComuneResidenza}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Cap</td>
                                                <td class="risposta">{{getCapResidenza}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Provincia</td>
                                                <td class="risposta">{{getProvinciaResidenza}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                                <br>
                            </div>
                            <div class="col-12 col-md-5" style="padding: 8px;">
                                <fieldset>
                                    <legend><strong>Corrispondenza</strong></legend>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td class="domanda">Indirizzo</td>
                                                <td class="risposta">{{getIndirizzoCorrispondenza}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Numero civico</td>
                                                <td class="risposta">{{getCivicoCorrispondenza}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Comune</td>
                                                <td class="risposta">{{getComuneCorrispondenza}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Cap</td>
                                                <td class="risposta">{{getCapCorrispondenza}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Provincia</td>
                                                <td class="risposta">{{getProvinciaCorrispondenza}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                                <br>
                            </div>

                            <div class="col-12 col-md-5" style="padding: 8px;">
                                <fieldset>
                                    <legend><strong>Recapiti</strong></legend>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td class="domanda">Telefono fisso</td>
                                                <td class="risposta">{{getTelefonoCliente}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Cellulare</td>
                                                <td class="risposta">{{getCellulareCliente}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Email</td>
                                                <td class="risposta">{{getEmailCliente}}</td>
                                            </tr>
                                            <tr>
                                                <td class="domanda">Email PEC</td>
                                                <td class="risposta">{{getPecCliente}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                            </div>
                        </div>

                    </q-tab-panel>

                    <q-tab-panel name="contratti" v-if="dati_caricati_dal_backend">

                        <table class="table-bordered">
                            <thead>
                                <tr>
                                    <th rowspan="2">Prodotto</th>
                                    <th colspan="2">Compagnia</th>
                                    <th rowspan="2">Frazionamento</th>
                                    <th rowspan="2">Stato</th>
                                    <th colspan="3">Premio rata</th>
                                </tr>
                                <tr>
                                    <th>Ragione Sociale</th>
                                    <th>Fornitore</th>
                                    <th>Premio Polizza</th>
                                    <th>Premio Acquisto</th>
                                    <th>Provvigioni</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(contratto,index) in prodotti_selezionati.contratti" v-bind:key="index">
                                    <td>{{contratto.label_prodotto}}</td>
                                    <td>{{contratto.compagnia.toUpperCase()}}</td>
                                    <td>{{contratto.fornitore}}</td>
                                    <td>{{getFrazionamentoContratto(contratto)}}</td>
                                    <td>{{getStatoPratica.replaceAll("_"," ")}}</td>
                                    <td align="right">{{getPremioPolizza(contratto.compagnia,contratto.tariffa) }} Euro</td>
                                    <td align="right">{{getPremioContratto(contratto.compagnia,contratto.tariffa)}} Euro</td>
                                    <td align="right">{{getProvvigioniContratto(contratto.compagnia,contratto.tariffa)}} Euro</td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td colspan="6" align="right"><strong>TOTALE</strong></td>
                                    <td align="right">{{getPremioTotale()}} Euro</td>
                                    <td align="right">{{getProvvigioniTotale()}} Euro </td>
                                </tr>
                            </tfoot>
                        </table>

                        <br><br>

                        <div align="center">

                            <div v-for="(contratto,index) in prodotti_selezionati.contratti" v-bind:key="index"
                                 style="max-width: 800px;"
                                 align="left">

                                <h5 class="q-mb-sm q-mt-sm"
                                    align="center"
                                    style="border: 1px SOLID #c0c0c0; background-color: #ffcc99; color: #000;">
                                    {{contratto.label_prodotto}}
                                </h5>
                                <q-list separator>
                                    <q-item>
                                        <q-item-section>
                                            <q-item-label>Compagnia</q-item-label>
                                            <q-item-label caption>{{contratto.compagnia.toUpperCase()}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>Frazionamento</q-item-label>
                                            <q-item-label caption>{{contratto.frazionamento.toUpperCase()}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>Numero Polizza Compagnia</q-item-label>
                                            <q-item-label caption>{{contratto.numero_polizza}}</q-item-label>
                                        </q-item-section>
                                    </q-item>
                                    <q-item>
                                        <q-item-section>
                                            <q-item-label>Decorrenza</q-item-label>
                                            <q-item-label caption>{{contratto.decorrenza}}</q-item-label>
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>Scadenza</q-item-label>
                                            <q-item-label caption>
                                                {{  contratto.scadenza }}
                                            </q-item-label>
                                        </q-item-section>
                                        <q-item-label>
                                            <q-item-label>Durata Anni</q-item-label>
                                            <q-item-label caption align="center">
                                                {{  contratto.durata_anni }}
                                            </q-item-label>
                                        </q-item-label>
                                    <!--    <q-item-section>
                                            <q-item-label>Scadenza contrattuale</q-item-label>
                                            <q-item-label caption>{{getDataScadenzaPratica()}}</q-item-label>
                                        </q-item-section> //-->
                                    </q-item>
                                    <q-item v-for="(parametro,indexParametri) in contratto.parametri"
                                        v-bind:key="indexParametri">
                                        <q-item-section>
                                            <q-item-label>{{parametro.label}}</q-item-label>
                                            <q-item-label caption>{{ analizzaRisposta(parametro)}}</q-item-label>
                                        </q-item-section>
                                    </q-item>

                                </q-list>

                            </div>
                        </div>

                    </q-tab-panel>

                    <q-tab-panel name="questionari">
                        <div class="row justify-center">
                            <div class="col-12 col-md-10">
                                <fieldset>
                                    <legend><strong>Analisi necessit&agrave;</strong></legend>
                                    <table class="table">
                                        <tbody>
                                            <tr v-for="(domanda,index) in getDomandeAnalisiNecessita"
                                                v-bind:key="index">
                                                <td class="domanda">{{domanda.label}} </td>
                                                <td class="risposta">{{ analizzaRisposta(domanda) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                                <br>
                                <fieldset>
                                    <legend><strong>Analisi rischio</strong></legend>

                                    <div v-for="(sezione_domanda,index) in getDomandeAnalisiRischio"
                                                v-bind:key="index">
                                        <h5 class="q-mb-sm q-mt-sm"
                                            align="center"
                                            style="border: 1px SOLID #c0c0c0;">
                                            {{sezione_domanda.label}}
                                        </h5>

                                        <table class="table">
                                            <tbody>
                                                <tr v-for="(domanda,indexDomanda) in sezione_domanda.domande.filter(d => {return d.value !== ''})"
                                                v-bind:key="indexDomanda">
                                                    <td class="domanda" v-html="domanda.label"></td>
                                                    <td class="risposta">{{ analizzaRisposta(domanda)}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </fieldset>
                            </div>
                        </div>

                    </q-tab-panel>

                    <q-tab-panel name="questionari_assuntivi">
                        <div class="row justify-center">
                            <div class="col-12 col-md-10">

                                <q-tabs
                                    v-model="tab_questionari"
                                    align="center"
                                    narrow-indicator
                                >

                                <!--    {{ questionario }} - {{ index }} //-->

                                <q-tab v-for="(questionario,index) in questionari_assuntivi" v-bind:key="index" :name="index" :label="index" class="text-weight-bolder" />

                                </q-tabs>

                                <q-tab-panels v-model="tab_questionari" animated ref="tabs"
                                    transition-prev="jump-down"
                                    transition-next="jump-down">

                                    <q-tab-panel v-for="(questionario,index,index_questionario) in questionari_assuntivi" v-bind:key="index" :name="index">

                                        <QQQuestionarioTecnicoDaIDPreventivo v-if="isRcTecnico(questionario)" :indice_questionario_rischio="index_questionario" :guid_preventivo="IDPratica" />
                                        <QQQuestionarioPersoneAziendeDaIDPreventivo  v-if="isAltroProdotto(questionario)" :indice_questionario_rischio="index_questionario" :guid_preventivo="IDPratica" />
                                        <QQQuestionarioSanitarioDaIDPreventivo  v-if="isRcSanitario(questionario)" :indice_questionario_rischio="index_questionario" :guid_preventivo="IDPratica" />
                                        <QQQuestionarioMedicoDaIDPreventivo  v-if="isRcMedico(questionario)" :indice_questionario_rischio="index_questionario" :guid_preventivo="IDPratica" />

                                    </q-tab-panel>
                                </q-tab-panels>

                            </div>
                        </div>
                    </q-tab-panel>

                    <q-tab-panel name="documenti" align="center">

                        <div style="max-width: 1000px; border: 1px SOLID #000;" class="shadow-12">
                            <QQElencoDocumentiPratica
                                align="left"
                                v-show="dati_caricati_dal_backend"
                                title=""
                                tabIndex = "0"
                                showContratti
                                :id_pratica="IDPratica"
                                :formPratica="formPratica"
                            />
                        </div>

                        <div align="center">

                            <h5>Allega altri documenti</h5>
                            <q-uploader
                                ref="UploadObjectScheda"
                                url="https://localhost:1000"
                                color="teal"
                                label="Trascina QUI i nuovi documenti da allegare alla pratica"
                                multiple
                                hide-upload-btn
                                :filter="checkFileType"
                                style="max-width: 800px; width: 100%; height: 300px; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
                                @rejected="onRejected"
                                @added="onAggiungiFileAllegati"
                                @removed="onRimuoviFileAllegati"
                                @uploading="onUploading"
                            />
                            <br>

                            <QQButton label="Upload Files" color="blue-grey" icon="save" size="md"
                                @click.native="onUploading"
                            />

                        </div>

                    </q-tab-panel>

                </q-tab-panels>
            </q-card>
        </div>
        <br><hr>
        <div class="row justify-center">

            <div class="col-md-4" align="center">
                <QQButton label="Torna al Menu" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked"
                />
            </div>

        </div>
        <br><br><br><br>
    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";
    import {mapFields} from "vuex-map-fields"
    import QQButton from "@/components/QQButton"
    import QQElencoDocumentiPratica from "@/components/QQElencoDocumentiPratica"
    import axios from 'axios';
    import commonLib from "@/libs/commonLib.js";
    import QQQuestionarioTecnicoDaIDPreventivo from "@/components/QuestionariAssuntivi/QQQuestionarioTecnicoDaIDPreventivo";
    import QQQuestionarioPersoneAziendeDaIDPreventivo from "@/components/QuestionariAssuntivi/QQQuestionarioPersoneAziendeDaIDPreventivo";
    import QQQuestionarioSanitarioDaIDPreventivo from "@/components/QuestionariAssuntivi/QQQuestionarioSanitarioDaIDPreventivo";
    import QQQuestionarioMedicoDaIDPreventivo from "@/components/QuestionariAssuntivi/QQQuestionarioMedicoDaIDPreventivo";

    export default {
        name: "SchedaDellaPratica",
        components: {
            QQButton,
            QQElencoDocumentiPratica,
            QQQuestionarioTecnicoDaIDPreventivo,
            QQQuestionarioPersoneAziendeDaIDPreventivo,
            QQQuestionarioSanitarioDaIDPreventivo,
            QQQuestionarioMedicoDaIDPreventivo
        },
        data() {
            return {
                tab: 'generali',
                tab_questionari: 'rcp_tecnico',
                totale_file_allegati: 0,
                files: [],
                tabNames: ["generali", "questionari", "cliente", "contratti", "documenti"],
                documenti_allegati: [],
                dati_economici: [],
                annotazione: "",
                premi_quietanze: [],
                premio_totale: 0,
                provvigioni_totale: 0,

                prodotti_selezionati: [],
                dati_caricati_dal_backend: false
            }

        },
        computed: {
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPratica: state => state.gestionePratiche.formPratica,
                IDPratica: state => state.gestionePratiche.formPratica.preventivo.id,
                elenco_documenti: state => state.gestioneDocumenti.elenco_documenti
            }),
            ...mapFields("gestionePratiche", {
                dati_cliente: "dati_cliente",
            }),
            ...mapFields("gestioneDocumenti", {
                elenco_documenti: "elenco_documenti"
            }),
            ...mapFields("gestioneQuotazioni", {
                questionari_assuntivi: "questionari_assuntivi"
            }),
            getNumeroPratica() {
                if (commonLib.isEmpty(this.formPratica.preventivo)) return "";

                return this.formPratica.preventivo.id;
            },
            getDataCreazionePratica() {
                if (commonLib.isEmpty(this.formPratica.preventivo)) return "";

                return this.formPratica.preventivo.create_at;
            },
            getProdottiSelezionati() {
                //return this.formPratica.preventivo.prodotti.filter(prodotto => {
                //    return prodotto.is_checked;
                //})

                return this.prodotti_selezionati;
            },
            getIDUnitaOperativa() {
                if (commonLib.isEmpty(this.formPratica.preventivo)) return "";

                return this.formPratica.id_unita_operativa;
            },
            getDomandeAnalisiNecessita() {
                if (commonLib.isEmpty(this.formPratica.preventivo)) return "";

                return this.formPratica.preventivo.analisi_necessita.filter(domanda => {
                    return domanda.value !== "";
                });
            },
            getDomandeAnalisiRischio() {
                if (commonLib.isEmpty(this.formPratica.preventivo)) return "";

                return this.formPratica.preventivo.analisi_rischio;
            },
            getDatiEconomiciPratica() {
                if (commonLib.isEmpty(this.formPratica.preventivo)) return "";

                var premi = this.dati_economici.filter(d => {
                    return d.tipo !== "PREMIO_LORDO_POLIZZA" && d.tipo !== "PROVVIGIONI_POLIZZA" && d.da_sommare === true;
                });

                return premi;
            },
            getStatoPratica() {
                if (commonLib.isEmpty(this.formPratica.preventivo)) return "";

                return this.formPratica.stato_pratica;
            },
            getDataDecorrenzaPratica() {
                if (commonLib.isEmpty(this.formPratica.preventivo)) return "";

                return this.formPratica.preventivo.decorrenza;
            },
            isPersonaFisica() {
                if (commonLib.isEmpty(this.formPratica.preventivo)) return "";

                let esito = this.dati_cliente.tipo_persona === "persona_fisica";
                return Boolean(esito);
            },
            getNominativoCliente() {
                let tipo_persona = this.dati_cliente.tipo_persona;
                switch(tipo_persona) {
                    case "persona_fisica":
                    case "professionista":
                        return this.dati_cliente.cognome + " " + this.dati_cliente.nome;
                    case "societa":
                        return this.dati_cliente.cognome;
                }

                return "";
            },
            getIndirizzoResidenza() {
                return this.dati_cliente.indirizzo_residenza;
            },
            getComuneResidenza() {
                return this.dati_cliente.comune_residenza;
            },
            getCivicoResidenza() {
                return "";
            },
            getCapResidenza() {
                return this.dati_cliente.cap_residenza;
            },
            getProvinciaResidenza() {
                return this.dati_cliente.provincia_residenza;
            },
            getIndirizzoCorrispondenza() {
                return this.dati_cliente.indirizzo_recapito;
            },
            getComuneCorrispondenza() {
                return this.dati_cliente.comune_recapito;
            },
            getCivicoCorrispondenza() {
                return "";
            },
            getCapCorrispondenza() {
                return this.dati_cliente.cap_recapito;
            },
            getProvinciaCorrispondenza() {
                return this.dati_cliente.provincia_recapito;
            },
            getProfessioneCliente() {
                return this.dati_cliente.professione;
            },
            getDataNascitaCliente() {
                return this.dati_cliente.data_nascita;
            },
            getComuneNascitaCliente() {
                return this.dati_cliente.comune_nascita;
            },
            getProvinciaNascitaCliente() {
                return this.dati_cliente.provincia_nascita;
            },
            getCodiceFiscaleCliente() {
                return this.dati_cliente.codice_fiscale;
            },
            getPartitaIvaCliente() {
                return this.dati_cliente.partita_iva;
            },
            getSessoCliente() {
                return this.dati_cliente.sesso;
            },
            getTelefonoCliente() {
                return this.dati_cliente.telefono;
            },
            getCellulareCliente() {
                return this.dati_cliente.cellulare;
            },
            getEmailCliente() {
                return this.dati_cliente.email;
            },
            getPecCliente() {
                return this.dati_cliente.pec;
            }
        },
        methods: {
            ...mapActions({
                fetchDownloadUrl: "gestioneDocumenti/fetchDownloadUrl",
                fetchUploadUrl: "gestioneDocumenti/fetchUploadUrl",
                fetchElencoDocumentiDaPreventivo: "gestioneDocumenti/fetchElencoDocumentiDaPreventivo",
                fetchNotePratica: "gestionePratiche/fetchNotePratica",
                fetchPremiQuietanzeAttive: "gestionePratiche/fetchPremiQuietanzeAttive",
                fetchDatiEconomiciPratica: "gestionePratiche/fetchDatiEconomiciPratica",
                fetchElencoContrattiPratica: "gestionePratiche/fetchElencoContrattiPratica",
                loadQuestionariAssuntivi: "gestioneQuotazioni/loadQuestionariAssuntivi",
                fetchDatiPreventivo: "formPreventivo/fetchDatiPreventivo"

            }),
            isRcTecnico(questionario) {
                console.log("RCTecnico sbagliato");
                return (questionario.id_prodotto === "rcp_tecnico");
            },
            isRcMedico(questionario) {
                console.log("RCMedico sbagliato");
                return (questionario.id_prodotto === "rcp_medico");
            },
            isRcSanitario(questionario) {
                console.log("RCSanitario sbagliato");
                return (questionario.id_prodotto === "rcp_sanitario");
            },
            isAltroProdotto(questionario) {
                console.log("Prodotto corretto");
                return ((!this.isRcTecnico(questionario))  &&
                        (!this.isRcMedico(questionario)) &&
                        (!this.isRcSanitario(questionario)));
            },
            getFrazionamentoContratto(contratto) {
                return contratto.frazionamento.toUpperCase();
            },
            getPremioContratto(compagnia,tariffa) {
                var premio = 0;
                //Scansiono tutti i dati economici associati
                var dati_economici = this.dati_economici.filter(d => {
                    return (d.tariffa.normalize() === tariffa.normalize()) && (d.da_sommare === true);
                });

                console.log("dati economici:",dati_economici);

                if (dati_economici.length === 0) {
                    var dati = this.premi_quietanze.elenco_quietanze.filter(p => {
                        return ((p.compagnia.normalize() === compagnia.normalize()) && (p.tariffa.normalize() === tariffa.normalize()));
                    });

                    return dati[0].premio.toFixed(2);
                }

                //console.log("dati_economici:",this.dati_economici);

                for (var i=0;i<dati_economici.length;i++) {
                    premio += dati_economici[i].importo;
                }

                return premio.toFixed(2);
            },
            getPremioPolizza(compagnia,tariffa) {
                var premio = 0;
                //Scansiono tutti i dati economici associati
                var dati_economici = this.dati_economici.filter(d => {
                    return (d.tariffa.normalize() === tariffa.normalize()) && (d.da_sommare === true);
                });

                if (dati_economici.length === 0) {
                    var dati = this.premi_quietanze.elenco_quietanze.filter(p => {
                        return ((p.compagnia.normalize() === compagnia.normalize()) && (p.tariffa.normalize() === tariffa.normalize()));
                    });

                    return dati[0].premio.toFixed(2);
                }

                var dati_premio = dati_economici.filter(d => {
                    return (d.da_sommare) && ((d.tipo === "PREMIO_LORDO_POLIZZA") ||
                            (d.tipo === "DIRITTI_TERZI") ||
                            (d.tipo === "DIRITTI_FORNITORE") ||
                            (d.tipo === "DIRITTI_EMITTENTE") ||
                            (d.tipo === "COSTO_SERVIZIO"));
                });

                for (var i=0;i<dati_premio.length;i++) {
                    premio += dati_premio[i].importo;
                }

                return premio.toFixed(2);
            },
            getProvvigioniContratto(compagnia,tariffa) {
                if (commonLib.isEmpty(this.premi_quietanze.elenco_quietanze)) {
                    return 0;
                }

                var dati_economici = this.dati_economici.filter(d => {
                    return (d.tariffa.normalize() === tariffa.normalize());
                });

                if (dati_economici.length === 0) {
                    var dati = this.premi_quietanze.elenco_quietanze.filter(p => {
                        return ((p.compagnia === compagnia) && (p.tariffa === tariffa));
                    });

                    if (dati.length === 0) return 0;

                    //this.provvigioni_totale += dati[0].provvigioni;
                    return dati[0].provvigioni.toFixed(2);
                }

                var provvigioni = 0;

                for (var i=0;i<dati_economici.length;i++) {
                    var dato = dati_economici[i];
                    if ((dato.tipo === "QUOTA_CONSULENZA_UNITA_OPERATIVA") ||
                        (dato.tipo === "PROVVIGIONI_POLIZZA")) {
                        provvigioni += dato.importo;
                    }
                }

                return provvigioni.toFixed(2);
            },
            getStatoContratto(compagnia,tariffa) {
                //console.log("getStatoContratto", [compagnia,tariffa, this.premi_quietanze.elenco_quietanze]);

                if (commonLib.isEmpty(this.premi_quietanze.elenco_quietanze)) {
                    return "";
                }

                var dati = this.premi_quietanze.elenco_quietanze.filter(p => {
                    return ((p.compagnia === compagnia) && (p.tariffa === tariffa));
                });

                //console.log("getStatoContratto dati", dati);

                //this.provvigioni_totale += dati[0].provvigioni;
                return dati[0].stato.replaceAll("_"," ");
            },
            getPremioTotale() {
                var premio = 0;

                if (this.dati_economici.length === 0) {
                    if (!commonLib.isEmpty(this.premi_quietanze)) {
                        this.premi_quietanze.elenco_quietanze.forEach(p => {
                            premio += p.premio;
                        });
                    }

                    return premio.toFixed(2);
                }

                //Scansiono tutti i dati economici associati
                var dati_economici = this.dati_economici.filter(d => {
                    return d.da_sommare === true;
                });

                for (var i=0;i<dati_economici.length;i++) {
                    premio += dati_economici[i].importo;
                }

                return premio.toFixed(2);
            },
            getProvvigioniTotale() {
                var provvigioni = 0;
                if (this.dati_economici.length === 0) {
                    if (!commonLib.isEmpty(this.premi_quietanze)) {
                        this.premi_quietanze.elenco_quietanze.forEach(p => {
                            provvigioni += p.provvigioni;
                        });
                    }

                    return provvigioni.toFixed(2);
                }

                for (var i=0;i<this.dati_economici.length;i++) {
                    var dato = this.dati_economici[i];
                    if ((dato.tipo === "QUOTA_CONSULENZA_UNITA_OPERATIVA") ||
                        (dato.tipo === "PROVVIGIONI_POLIZZA")) {
                            provvigioni += dato.importo;
                        }
                }

                return provvigioni.toFixed(2);
            },
            getDataScadenzaPratica() {
                var data = this.getDataDecorrenzaPratica.split("/");
                var decorrenza = new Date(data[2],data[1],data[0]);
                var scadenza = new Date(decorrenza.getFullYear()+1,decorrenza.getMonth(),decorrenza.getDate());
                var giorno = scadenza.getDate()+'';
                var mese   = scadenza.getMonth()+'';

                giorno = giorno.padStart(2,'0');
                mese = mese.padStart(2,'0');

                return giorno+'/'+mese+'/'+scadenza.getFullYear();
            },
            getDataScadenzaRata(contratto) {
                var data = this.getDataDecorrenzaPratica.split("/");
                var decorrenza = new Date(data[2],data[1],data[0]);
                var scadenza = new Date(decorrenza.getFullYear()+1,decorrenza.getMonth(),decorrenza.getDate());
                var giorno = scadenza.getDate();
                var mese   = scadenza.getMonth();
                var anno   = scadenza.getFullYear();
                var frazionamento = contratto.frazionamento.toUpperCase();

                switch(frazionamento) {
                    case "SEMESTRALE":
                        anno = decorrenza.getFullYear();
                        mese += 6;
                        if (mese > 12) {
                            mese -=12;
                            anno++;
                        }
                    break;
                }

                giorno += '';
                mese += '';
                if (giorno.length < 2) giorno = "0"+giorno;
                if (mese.length < 2) mese = "0" + mese;
                return giorno+"/"+mese+"/"+anno;
            },
            CalcolaProvvigioni(proposta) {
                return proposta.provvigioni.toFixed(2);
            },
            getValueDocument(documento) {
                //console.log("documento:",documento);
                if (commonLib.isEmpty(documento)) return [];

                return documento.filter(descr => { return descr.is_checked; })
            },
            async onScaricaDocumento(documento) {
                var NumeroPreventivo = this.getNumeroPratica;
                var anno = documento.annoCreazione;
                var mese = documento.meseCreazione+"";

                mese = mese.padStart(2,"0");

                var Filename = "documenti/preventivi/"+anno+"/"+mese+"/"+NumeroPreventivo+"/"+documento.label;

                // Richiese url per il download
                var url = await this.fetchDownloadUrl(Filename);

                window.location = url;
            },
            onIndietroClicked() {
                this.$router.push({name : "Pratiche.MenuGestionePratiche"});
            },
            analizzaRisposta(parametro) {
                let result = "";

                if (parametro.type === "checkbox") {
                    return parametro.is_checked ? "Si" : "No";
                }

                var value = parametro.value;
                var options = parametro.options;

                // Cerca la label tra le options in base alla risposta inserita
                let element = options.filter(elemento => {
                    return elemento.value === value;
                })
                if (element.length === 1) {
                    value = element[0].label;
                }

                if ((value !== null) && (Array.isArray(value))) {
                    value.forEach(valore => {
                        result += '"'+valore+'", ';
                    });
                    result = result.slice(0, -2);



                    return result.replace("_"," ");
                } else {

                    if (value !== null) {
                        return value.replace("_"," ");
                    } else
                        return "";
                }


            },
            onAggiungiFileAllegati(files) {
                this.totale_file_allegati += files.length;

                this.elenco_documenti.forEach(p => {
                    p.is_checked = false;
                });

                var name = files[0].name;
                var valori = [
                    {
                        "descrizione_documento": "Documento aggiuntivo",
                        "is_checked": true
                    }
                ];

            //    for (var i=0;i<this.elenco_documenti.length;i++) {
            //        valori[i] = {
            //            "descrizione_documento": this.elenco_documenti[i].descrizione_documento,
            //            "is_checked": this.elenco_documenti[i].is_checked
            //        }
            //    }

                var create_at = this.formPratica.preventivo.create_at;

                //console.log("create_at:",create_at);

                var elements = create_at.split("/");

                var documento = {
                    "label": name,
                    "file_size": files[0].size,
                    "tipo_documento": files[0].type,
                    "value": valori,
                    "AnnoCreazione": elements[2],
                    "MeseCreazione": elements[1]
                }

                this.documenti_allegati.push(documento);
                //this.show_dialog = true;

            },
            onRimuoviFileAllegati(files) {
                this.totale_file_allegati -= 1;
                if (this.totale_file_allegati < 0) this.totale_file_allegati = 0;

                // Identifica il record che contiene il documento per cancellarlo dall'array
                var name = files[0].name;
                var IndiceDaCancellare = -1;

                for (var i=0;i<this.documenti_allegati.length;i++) {
                    if (this.documenti_allegati[i].label === name) {
                        IndiceDaCancellare = i;
                    }
                }

                if (IndiceDaCancellare !== -1) {
                    this.documenti_allegati.splice(IndiceDaCancellare,1);
                }

            },
            checkFileType(files) {
                let status = (files.filter(file => file.type === 'application/pdf').length === 1) ||
                             (files.filter(file => file.type === 'application/x-compressed').length === 1) ||
                             (files.filter(file => file.type === 'application/x-zip-compressed').length === 1) ||
                             (files.filter(file => file.type === 'image/png').length === 1) ||
                             (files.filter(file => file.type === 'image/jpeg').length === 1) ||
                             (files.filter(file => file.type === 'image/gif').length === 1) ||
                             (files.filter(file => file.type === 'application/vnd.fdf').length === 1);
                if (status) {
                    return files.filter(file => file.type === files[0].type);
                }

                return [];
            },
            onRejected () {
                this.$q.dialog({
                    title: 'Attenzione',
                    message: 'Il tipo di documento allegato può essere solo nel formato ZIP,PDF,FDF,PNG,JPG,JPEG,GIF. Gli altri formati non sono accettati per questioni di sicurezza.'
                });
            },
            async onUploading() {
                this.files = this.$refs.UploadObjectScheda.files;
                this.elenco_documenti = [];

                for( var i = 0; i < this.files.length; i++ ) {
                    let file = this.files[i];
                    let formData = new FormData();
                    formData.append('files[' + i + ']', file);

                    var documento = this.documenti_allegati[i];
                    this.elenco_documenti.push(documento);

                    // recupera url da S3
                    let url = await this.fetchUploadUrl({IDPreventivo:this.formPratica.preventivo.id,
                                                            Filename:file.name,
                                                            InfoDocumento:documento});

                    if (url.error !== "") {
                        this.$q.dialog({
                            title: 'Attenzione',
                            message: url.error
                        });

                        return;
                    }

                    await axios.put(
                        url.data,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(function(){
                            //console.log('SUCCESS!!');
                        }).catch(function(){
                            //console.log('FAILURE!!');
                        });

                }

                // Cancella la lista dei documenti allegati
                this.$refs.UploadObjectScheda.removeUploadedFiles();
                this.$refs.UploadObjectScheda.removeQueuedFiles();

                this.elenco_documenti = await this.fetchElencoDocumentiDaPreventivo(this.getNumeroPratica);
                this.elenco_documenti = this.elenco_documenti.data.result;
            }
        },
    /*    async mounted() {
            this.premio_totale = 0;
            this.provvigioni_totale = 0;

            let traversal = this.tabNames.reduce((promiseChain, item) => {
                return promiseChain.then(() => new Promise(resolve => {
                    //console.log("done with", item);
                    resolve();
                    this.$refs.tabs.goTo(item);
                })
                )
            }, Promise.resolve());

            traversal.then(() => {
                //console.log("done")
                this.$refs.tabs.goTo('documenti');
                this.files = this.$refs.UploadObjectScheda.files;
                this.$refs.tabs.goTo('generali');
            });

            this.annotazione = "";

            if (!commonLib.isEmpty(this.formPratica)) {
                var num_pratica = this.formPratica.preventivo.id;

                this.annotazione = await this.fetchNotePratica(num_pratica);
                this.premi_quietanze = await this.fetchPremiQuietanzeAttive(num_pratica);

                await this.fetchDatiPreventivo(num_pratica);
            }

            this.prodotti_selezionati = await this.fetchElencoContrattiPratica(num_pratica);

            //console.log("IDPratica:",this.IDPratica);

            await this.loadQuestionariAssuntivi(num_pratica);
            if (this.questionari_assuntivi.length > 0) {
                //console.log("id_prodotto:",this.prodotti_selezionati);
                this.tab_questionari = "rcp_tecnico";
            }
        },*/
        async mounted() {
            if (!commonLib.isEmpty(this.formPratica)) {
                var num_pratica = this.formPratica.preventivo.id;

                this.annotazione = await this.fetchNotePratica(num_pratica);
                this.premi_quietanze = await this.fetchPremiQuietanzeAttive(num_pratica);
                this.dati_economici = await this.fetchDatiEconomiciPratica(num_pratica);
                this.prodotti_selezionati = await this.fetchElencoContrattiPratica(num_pratica);

                await this.fetchDatiPreventivo(num_pratica);
            }

            this.dati_caricati_dal_backend = true;

            //console.log("IDPratica:",this.IDPratica);
            await this.loadQuestionariAssuntivi(num_pratica);


            this.tab_questionari = this.prodotti_selezionati.contratti[0].prodotto_id;
        }

    }


</script>

<style scoped>
    td.domanda {
        text-align: left;
        font-style: italic;
        border-bottom: 1px dashed #c0c0c0;
    }
    td.risposta {
        border-bottom: 1px SOLID #c0c0c0;
        padding: 6px;
        width: 60%;
        font-weight: bold;
        vertical-align: bottom;
    }
    .table {
        width: 100%;
    }
    .table-bordered {
        border-collapse: collapse;
        width: 100%;
    }

    .table-bordered tbody tr td, .table tr th {
        /*border: 1px solid #404040;*/
        border: 1px solid;
        padding: 8px;
    }

    .table-bordered thead tr th {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        text-align: left;
        /*color: #404040;
        background-color: #D0D0D0;*/
        border: 1px SOLID #404040;
        background-color: #ffcc99;
        color: #000;
    }

    .table-bordered tFoot tr td {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        padding-right: 8px;
        text-align: right;
        border: 1px SOLID;

    }
</style>

